import React, { useState } from "react";

export const StateContext = React.createContext();

export const StateProvider = ({ children }) => {
  const [locale, setLocale] = useState("pl");
  const [altMenuColor, setAltMenuColor] = useState(true);
  const [modal, setModal] = useState(false);

  return (
    <StateContext.Provider
      value={{
        locale,
        setLocale,
        altMenuColor,
        setAltMenuColor,
        modal,
        setModal,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
