import styled from "styled-components";

export const GlobalWrapper = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
`;

export const SectionWrapper = styled.div`
    width: 100%;
    max-width: 1428px;
    margin: 0 auto;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        max-width: 1198px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 1104px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        max-width: 956px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 820px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        max-width: 648px;
        padding-left: 24px;
        padding-right: 24px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        max-width: 572px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        max-width: 100%;
    }
`;

export const SectionsWrappper = styled.div`
    margin-top: 100vh;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin-top: 0;
        
    }
`
