import React, {useEffect, useRef, useState} from "react";
import {ActiveMenuContainer, BigLink, Column, Container, Copy, CopyItem, CopyLink, DecorationText, IvLogo, List, ListItem, Locales, LocaleSwitch, Logo, LogoWrapper, MenuToggler, Nav, NavWrapper, SmallLink, StyledGlobalWrapper, SubList, SubMenuHeader, SubMenuSwitcher, Wrapper, WumaxLogo,} from "./styles";
import getNavbarData from "data/useNavbarData";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";
import DefaultLogo from "assets/image/navbar/menu-foto-kennametal.png";
import uuid from "react-uuid";
import pages from "data/pagesMap";
import {useOrientationChange} from "shared/Hooks/useOrientationChange";
import {useDispatch, useSelector} from "react-redux";
import {setLocale} from "../../store/modals";
import {useScrollPosition} from "../../shared/Hooks/useScrollPosition";

const Navbar = () => {
  const location = useLocation();
  const data = getNavbarData();
  const {locale} = useSelector(state => state.modals);
  const {hero, footer} = useSelector(state => state.menuColor);
  const [altMenuColor, setAltMenuColor] = useState(hero || footer);
  const [menuActive, setMenuActive] = useState(false);
  const localeData = locale === "en" ? data.en : data.pl;
  const [translateLogo, setTranslateLogo] = useState(false);
  const [hide, setHide] = useState(false);
  const subListRef = useRef(null);
  const imgRef = useRef(null);
  const [subListHeight, setSubListHeight] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const orientation = useOrientationChange();
  const dispatch = useDispatch();
  const isBrowser = typeof window !== `undefined`;

  useEffect(() => {

    if (hero) {
      setAltMenuColor(true)
    } else if (footer) {
      setAltMenuColor(true)
    } else {
      setAltMenuColor(false)
    }
  }, [hero, footer, location]);

  const handleOnClick = () => {
    if (!menuActive) {
      setMenuActive(true);

      if (isBrowser) {
        document.body.classList.add("blocked");
      }
    } else {
      setMenuActive(false);
      if (isBrowser) {
        document.body.classList.remove("blocked");
      }
    }
  };

  const handleLogoClick = () => {
    setMenuActive(false);
    if (isBrowser) {
      document.body.classList.remove("blocked");
    }
  };

  const handleSubList = () => {
    subListRef.current.classList.toggle("active");
  };

  const handleLocaleSwitch = (lang) => {
    dispatch(setLocale(lang));

    const hrefs = location.href.replace(/\/?(#.*)?$/, "").split("/");
    const page = hrefs[hrefs.length - 1] + location.hash;

    // On develop add localhost here for testing
    if (page === "en" || page === process.env.GATSBY_SITE_URL) {
      navigate(`${pages[locale]["homepage"]}`);
    } else {
      navigate(`${pages[locale][page]}`);
    }
  };

  useEffect(() => {
    setHide(false);
  }, [orientation]);

  useEffect(() => {
    if (!hide) {
      setSubListHeight(subListRef.current.offsetHeight);
      setHide(true);
    }
  }, [hide]);

  useScrollPosition(({currPos}) => {

    if (currPos.y < -200) {
      setTranslateLogo(true);
    } else {
      setTranslateLogo(false);
    }

    if (currPos.y < -(window.innerHeight / 10)) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  return (
    <Container altMenuColor={altMenuColor} scrolled={scrolled}
    >
      <WumaxLogo
        to={locale === "en" ? '/en' : '/'}
        title="Homepage"
        className={
          menuActive
          ? "active logo"
          : translateLogo
            ? "translate logo"
            : "closed logo"
        }
        onClick={() => handleLogoClick()}
      />
      <Wrapper>
        <Locales altMenuColor={altMenuColor} className={menuActive ? 'menuActive' : ''} menuActive={menuActive}>
          <LocaleSwitch
            className={locale === "pl" ? "selectedLocale disabled" : ""}
            onClick={() => handleLocaleSwitch("pl")}
          >
            PL
          </LocaleSwitch>
          <LocaleSwitch
            className={locale === "en" ? "selectedLocale disabled" : ""}
            onClick={() => handleLocaleSwitch("en")}
          >
            EN
          </LocaleSwitch>
          <MenuToggler
            altMenuColor={altMenuColor}
            className={menuActive ? "white" : ""}
            onClick={() => handleOnClick()}
          >
            {menuActive ? (locale === "pl" ? "Zamknij" : "Close") : "Menu"}
          </MenuToggler>
        </Locales>

      </Wrapper>
      <ActiveMenuContainer className={menuActive ? "active" : "closed"}>
        <DecorationText className={menuActive ? "active" : "closed"}>
          wumax
        </DecorationText>
        <StyledGlobalWrapper>
          <NavWrapper>
            <Column className={menuActive ? "active" : "closed"}>
              <LogoWrapper>
                <Logo src={DefaultLogo} alt="Logo" ref={imgRef}/>
              </LogoWrapper>
            </Column>
            <Column className={menuActive ? "active" : "closed"}>
              <Nav>
                <List>
                  {localeData.links.map((link) =>
                    link.links ? (
                      <React.Fragment key={uuid()}>
                        <SubMenuSwitcher onClick={handleSubList}>
                          <SubMenuHeader
                            onMouseOver={() => (imgRef.current.src = link.logo)}
                          >
                            {link.text}
                          </SubMenuHeader>
                          <SubList
                            ref={subListRef}
                            subListHeight={subListHeight}
                            className={hide ? 'hide' : ''}
                          >
                            {link.links.map((subLink) => (
                              <ListItem
                                key={uuid()}
                                onMouseOver={() =>
                                  (imgRef.current.src = subLink.logo)
                                }
                              >
                                <SmallLink
                                  to={subLink.slug}
                                  onClick={() => handleOnClick()}
                                >
                                  {subLink.text}
                                </SmallLink>
                              </ListItem>
                            ))}
                          </SubList>
                        </SubMenuSwitcher>
                      </React.Fragment>
                    ) : (
                      <ListItem
                        onMouseOver={() => (imgRef.current.src = link.logo)}
                        key={uuid()}
                      >
                        <BigLink to={link.slug} onClick={() => handleOnClick()}>
                          {link.text}
                        </BigLink>
                      </ListItem>
                    )
                  )}
                </List>
                <Copy>
                  <CopyItem>
                    <CopyLink
                      target="_blank"
                      href={localeData.copy.privacy.slug}
                    >
                      {localeData.copy.privacy.text}
                    </CopyLink>
                  </CopyItem>
                  <CopyItem>
                    <CopyLink href={localeData.copy.creator.slug} target="_blank" rel="noopener">
                      {localeData.copy.creator.text}
                      <IvLogo
                        src={localeData.copy.creator.logo}
                        alt="Interactive Vision"
                      />
                    </CopyLink>
                  </CopyItem>
                </Copy>
              </Nav>
            </Column>
          </NavWrapper>
        </StyledGlobalWrapper>
      </ActiveMenuContainer>
    </Container>
  );
};

export default Navbar;
