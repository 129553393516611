import styled from "styled-components";
import { SectionWrapper } from "shared/Wrappers/wrappers";
export const Container = styled.footer`
  min-height: 100vh;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black};
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style .gm-style-iw {
    background-color: transparent !important;
    top: 110px !important;
    left: 10px !important;
    width: auto !important;
    height: auto !important;
    min-height: auto !important;
    display: block !important;
  }
  .gm-style .gm-style-iw #google-popup p {
    padding: 10px;
  }

  .gm-style div div div div div div div div {
    background-color: transparent !important;
    padding: 0;
    margin: 0;
    top: 0;
    color: #fff;
    font-size: 16px;
  }

  /*style the link*/
  .gm-style div div div div div div div div a {
    color: #f1f1f1;
    font-weight: bold;
  }

  // arrow
  .gm-style-iw-t {
    &::after {
      display: none;
    }
  }
  .gm-ui-hover-effect {
    display: none !important;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}) and (orientation: landscape) {
    padding-bottom: 200px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    padding-bottom: 0px;
  }
`;

export const FooterTitleWrapper = styled(SectionWrapper)`
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  h2 {
    max-width: 900px;
    color: ${({ theme }) => theme.colors.white};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    h2 {
      text-align: center;
    }
  }
`;

export const ContactBox = styled(SectionWrapper)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
    padding: 0 24px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding-top: 32px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column;
  }
`;

export const CompanyLogo = styled.img`
  width: 46px;
  height: 46px;
  opacity: 0.3;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 34px;
    height: 34px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    order: 4;
    margin-top: 16px;
  }
`;

export const Address = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    margin-right: 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin: 10px 0;
  }
`;

export const ListItem = styled.li`
  display: flex;
  &:not(:first-child) {
    margin-top: 8px;
  }

  a,
  p {
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.weights.light};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;

export const Icon = styled.img`
  width: 30px;
  margin-right: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-right: 0px;
    margin-bottom: 6px;
  }
`;

export const FbLink = styled.a`
    display: flex;
    align-items: center;
    position: relative;

    span {
        color: ${({ theme }) => theme.colors.white};
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.3s ease-in-out;
        background-color: white;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet_big}) {
        &:hover {
            &::after {
                width: 0;
            }
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
        width: 100%;
        justify-content: center;
        padding-top: 26px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
`;

export const Fb = styled.img`
  height: 30px;
  margin-right: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-right: 0px;
    margin-bottom: 6px;
  }
`;
