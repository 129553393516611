import React, { useEffect, useRef, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import PinBar from "assets/image/wumax-marker.png";
import { Container, DirectionsLink } from "./styles";
import googleMapStyles from "./mapStyles";
const MapContainer = ({ google, mapStyle, lat, lng, name, zoom = 13 , locale}) => {
  const [isReady, setIsReady] = useState(false);
  const [markerClicked, setMarkerClicked] = useState(false);
  useEffect(() => {
    if (google) setIsReady(true);
  }, [google]);
  const markerRef = useRef(null);

  const mapLoaded = (mapProps, map) => {
    setMarkerClicked(true);
  };

  return (
    <Container className="map">
      {isReady && (
        <Map
          google={google}
          zoom={zoom}
          styles={mapStyle}
          initialCenter={{
            lat: lat,
            lng: lng,
          }}
          center={{
            lat: lat,
            lng: lng,
          }}
          zoomControl={false}
          scaleControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          draggable={true}
          // scrollwheel={false}
          onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        >
          <Marker
            name={name}
            ref={markerRef}
            position={{ lat: lat, lng: lng }}
            onClick={() => setMarkerClicked(!markerClicked)}
            icon={{
              url: PinBar,
              anchor: new google.maps.Point(18, 18),
              scaledSize: new google.maps.Size(54, 70),
            }}
          />
          <InfoWindow visible={markerClicked} position={{ lat: lat, lng: lng }}>
            <DirectionsLink
              href="https://goo.gl/maps/CRDmv3oSWcCTXK2eA "
              target="_blank"
              rel="noopener"
            >
              {locale === "pl" ? "Jak dojechać" : "How to get there"}
            </DirectionsLink>
          </InfoWindow>
        </Map>
      )}
    </Container>
  );
};

MapContainer.defaultProps = googleMapStyles;

export default GoogleApiWrapper({
  apiKey: "AIzaSyDwzqxXsgv3IUXNsDGTa8Eb9sbm2n2lork",
})(MapContainer);
