/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import {StateProvider} from "./src/provider/StateProvider";
import "assets/fonts/fonts.css";
import "shared/Styles/reset.css";
import "shared/Styles/global.css";
import {Provider} from "react-redux"
import {store} from './src/store/index';

export const wrapRootElement = ({element}) => {
  return <Provider store={store}>
    <StateProvider>{element}</StateProvider> </Provider>

};
