const theme = {
  colors: {
    white: "#fff",
    gray: "#9C9C9C",
    light_gray: "#e3e3e3",
    dark_gray: "#2B2B2B",
    yellow: "#fff200",
    light_black: "#121212",
    black: "#000",
    gray_light_bg: "#fbfbfb",
    gray_text: "#696969",
    yellow_dark: "#e5d900",
  },
  fonts: {
    xl: {
      fhd: "60px",
      laptop_big: "50px",
      laptop: "46px",
      laptop_small: "40px",
      tablet_big: "34px",
      tablet: "28px",
    },
    l: {
      fhd: "28px",
      laptop: "22px",
      tablet: "20px",
    },
    m: {
      fhd: "24px",
      laptop: "20px",
      tablet: "18px",
    },
    s: {
      fhd: "20px",
      laptop: "16px",
      tablet: "14px",
    },
  },
  weights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  breakpoints: {
    laptop_big: "1601px",
    laptop: "1480px",
    laptop_small: "1281px",
    tablet_big: "1100px",
    tablet: "867px",
    tablet_small: "769px",
    phone_big: "601px",
    phone: "431px",
    phone_small: "374px",
  },
};

export default theme;

// z index list:
// navbar: 10
