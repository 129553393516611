export const PDF_MODAL = {
  toggle: "PDF_MODAL_TOGGLE",
};

export const FORM_MODAL = {
  toggle: 'FORM_MODAL_TOGGLE',
}

export const LOCALE = {
  set: 'SET_LOCALE',
}
