import styled from "styled-components";

export const TitleXL = styled.h2`
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.black)};
  font-size: ${({ theme }) => theme.fonts.xl.fhd};
  font-weight: ${({ weight }) =>
    weight ? weight : ({ theme }) => theme.weights.bold};
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_big}) {
    font-size: ${({ theme }) => theme.fonts.xl.laptop_big};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.xl.laptop};
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    font-size: ${({ theme }) => theme.fonts.xl.laptop_small};
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: ${({ theme }) => theme.fonts.xl.tablet_big};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.xl.tablet};
  }
`;

export const TitleL = styled.h3`
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.black)};
  font-size: ${({ theme }) => theme.fonts.l.fhd};
  font-weight: ${({ weight }) =>
    weight ? weight : ({ theme }) => theme.weights.bold};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.l.laptop};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.l.tablet};
  }
`;

export const TitleM = styled.h3`
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.black)};
  font-size: ${({ theme }) => theme.fonts.m.fhd};

  font-weight: ${({ weight }) =>
    weight ? weight : ({ theme }) => theme.weights.bold};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.m.laptop};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.m.tablet};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: ${({ theme }) => theme.fonts.m.phone};
  }
`;

export const Paragraph = styled.p`
  color: ${({ color }) => (color ? color : ({ theme }) => theme.colors.black)};
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fonts.s.fhd};

  font-weight: ${({ weight }) =>
    weight ? weight : ({ theme }) => theme.weights.normal};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: ${({ theme }) => theme.fonts.s.laptop};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fonts.s.tablet};
  }
`;
