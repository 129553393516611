import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

export const Transition = ({ location, children }) => {
  const timeout = 400;
  const getOpacityStyles = {
    entering: {
      position: "relative",
      opacity: 0,
    },
    entered: {
      position: "relative",
      transition: `${timeout}ms all ease-in-out`,
      opacity: 1,
    },
    exiting: {
      position: "relative",
      transition: `${timeout}ms all ease-in-out`,
      opacity: 0,
    },
  };
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <>
            <div style={{ ...getOpacityStyles[status] }}>{children}</div>
          </>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};
