import location from "assets/svg/contact-yellow.svg";
import phone from "assets/svg/tel-yellow.svg";
import email from "assets/svg/email-yellow.svg";

const data = {
  titleEN: "Innovative solutions for your company",
  title: "Innowacyjne rozwiązania dla Twojej firmy",
  addressEN: {
    icon: location,
    list: [
      "WUMAX Agata Wermińska",
      "Ul. Wolności 261",
      "37-403 Pysznica",
      "POLAND",
      "Office hours: 7:30 - 15:30",
    ],
  },
  address: {
    icon: location,
    list: [
      "WUMAX Agata Wermińska",
      "Ul. Wolności 261",
      "37-403 Pysznica",
      "POLAND",
      "Biuro czynne: 7:30 - 15:30",
    ],
  },
  contact: {
    list: [
      { icon: email, text: "biuro@wumax.pl" },
      { icon: phone, text: "+48 691 967 840" },
      { icon: phone, text: "+48 787 994 777" },
      { icon: phone, text: "+48 532 202 777" },
    ],
  },
};

export default data;
