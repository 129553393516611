import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden;
    min-height: 100vh;
    height: 100%;
    position: relative;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        min-height: 80vh;
    }
`;

export const DirectionsLink = styled.a`
    color: white;
    font-size: 20px;
    visibility: visible;
    cursor: pointer;
    position: relative;
    overflow: visible !important;
    padding-bottom: 2px;

    &::after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 0;
        height: 2px;
        transition: width 0.3s ease-in-out;
        background-color: white;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    @media screen and (max-width: 601px) {
        &::after {
            height: 1px;
        }
    }
`;
