import styled, {css} from "styled-components";
import {Link} from "gatsby";
import {GlobalWrapper, SectionWrapper} from "shared/Wrappers/wrappers";
import ArrowLeft from "assets/image/navbar/arrow-left.png";
import WumaxLogoSvg from "assets/svg/wumaxlogo_kolko.svg";
import WumaxLogoSvgWhite from "assets/svg/wumaxlogo_kolko_white.svg";

export const Container = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 70px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    
    .logo {
        background-image: ${({altMenuColor}) =>
                altMenuColor ? `url(${WumaxLogoSvgWhite})` : `url(${WumaxLogoSvg})`};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 40px;
        background-color: ${({theme}) => theme.colors.white};
        align-items: center;
        
        .logo {
            background-image: url(${WumaxLogoSvg});
        }

        transition: box-shadow 0.3s;
        box-shadow: ${({scrolled}) => scrolled ? '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' : ''};
    }
`;

export const Wrapper = styled.div`
    display: flex;
    margin-right: 48px;
    z-index: 2;
    align-items:flex-end;


    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-right: 24px;
    }
`;

export const WumaxLogo = styled(Link)`
    width: 50px;
    height: 50px;
    z-index: 2;
    margin-left: 48px;
    opacity: 0;
    transition: opacity 0.3s, background-image 0.3s, transform 0.3s;
    transition-delay: 0.6s, 0.6s, 0.1s;
    transform: translate(-100px, calc(50% - 7px));

    &.active {
        opacity: 1;
        background-image: url(${WumaxLogoSvgWhite});
        transform: translate(0px, calc(50% - 7px));
    }

    &.closed {
        opacity: 0;
        transform: translate(-100px, calc(50% - 7px));
    }

    &.translate {
        transition: opacity 0.5s, background-image 0.5s, transform 0.3s;
        transition-delay: 0.1s, 0.1s, 0s;
        transform: translate(0px, calc(50% - 7px));
        opacity: 1;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 30px;
        width: 30px;

        &.active {
            opacity: 1;
            background-image: url(${WumaxLogoSvgWhite});
            transform: translateX(0px);
        }

        &.closed {
            opacity: 1;
            transform: translateX(0px);
        }

        &.translate {
            transform: translateX(0px);
            opacity: 1;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-left: 24px;
    }
`;

export const Locales = styled.div`
    display: flex;
    align-items:center;
    transition: background-color 0.3s;
    transition-delay: 0.2s;
    
    &.menuActive{
        background-color: ${({theme}) =>  theme.colors.black};
        padding-left: 6px;
    }
    
    button {
        &.selectedLocale {
            color: ${({theme, altMenuColor}) =>
                    altMenuColor ? theme.colors.white : theme.colors.black};
            color: ${({theme, menuActive}) =>
                    menuActive ? theme.colors.white : ""};
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        button {
            &.selectedLocale {
                color: ${({theme, menuActive}) =>
                        menuActive ? theme.colors.white : theme.colors.black};
            }
        }
    }
`;

export const LocaleSwitch = styled.button`
    outline: none;
    border: none;
    background: none;
    font-weight: ${({theme}) => theme.weights.semibold};
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    mix-blend-mode: difference;
    transition: 0.3s color;
    color: ${({theme}) => theme.colors.gray};

    &.disabled {
        pointer-events: none;
    }

    &:first-child {
        margin-right: 12px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 14px;
    }
`;

export const MenuToggler = styled.span`
    font-size: 18px;
    margin-left: 26px;
    font-weight: ${({theme}) => theme.weights.semibold};
    transition: 0.3s color;
    color: ${({theme, altMenuColor}) =>
            altMenuColor ? theme.colors.white : theme.colors.black};
    cursor: pointer;
    mix-blend-mode: difference;

    &.white {
        color: ${({theme}) => theme.colors.white};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 14px;
        color: ${({theme}) => theme.colors.black};
    }
`;

export const ActiveMenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({theme}) => theme.colors.black};
    transition: transform 0.5s;
    transform: translateX(100%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: ${({theme}) => theme.colors.light_black};
        transition: width 0.3s;
        transition-delay: 0.5s;
        width: 0%;
        z-index: -1;
    }

    &.active {
        transform: translateX(0);

        &::before {
            width: 30%;
        }
    }

    &.closed {
        transform: translateX(100%);
        transition-delay: 0.6s;

        &::before {
            transition-delay: 0.3s;
        }
    }
`;

export const DecorationText = styled.span`
    position: absolute;
    right: 25%;
    top: -30%;
    font-size: 500px;
    color: hsla(0, 0%, 100%, 0.08);
    z-index: 5;
    font-weight: 900;
    writing-mode: vertical-lr;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.8s;

    &.active {
        opacity: 1;
    }

    &.closed {
        transition-delay: 0.1s;
    }
`;
export const StyledGlobalWrapper = styled(GlobalWrapper)`
    height: 100%;
    overflow: auto;

`;

export const NavWrapper = styled(SectionWrapper)`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: calc(100% - 70px);
    margin-top: 70px;
    text-align: right;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 100% !important;
        padding: 0 48px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: calc(100% - 40px);
        margin-top: 40px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding: 0 24px;
    }
`;

export const Column = styled.div`
    width: 50%;
    height: 100%;
    transition: opacity 0.3s;
    transition-delay: 0.8s;
    opacity: 0;

    &.active {
        opacity: 1;
    }

    &.closed {
        transition-delay: 0.1s;
    }

    &:first-child {
        display: flex;
        align-items: center;
    }

    &:last-child {
        padding-top: 48px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 100%;
        position: relative;

        &:first-child {
            display: none;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:last-child {
            padding-top: 0px;
        }
    }
`;

export const LogoWrapper = styled.div`
    width: 520px;
    height: 525px;
    background-color: ${({theme}) => theme.colors.light_gray};
    position: relative;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        width: 433px;
        height: 438px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 401px;
        height: 405px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        width: 347px;
        height: 350px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 298px;
        height: 301px;
    }
`;
export const Logo = styled.img`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 115%;
`;

export const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
`;

export const List = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 20px;
    position: relative;
    

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        max-width: 230px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        max-width: 200px;
    }
`;

export const hoverStyles = css`
    position: relative;
    padding-bottom: 6px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 0;
        height: 2px;
        transition: width 0.3s ease-in-out;
        background-color: ${({theme}) => theme.colors.white};
    }

    &::before {
        content: "";
        position: absolute;
        right: -48px;
        top: 50%;
        transition: width 0.2s ease-in-out;
        width: 0;
        height: 8px;
        background-image: url(${ArrowLeft});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &:hover {
        &::after {
            width: 100%;
        }

        &::before {
            width: 28px;
        }
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        &::before {
            display: none;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &::after {
            height: 2px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        &::after {
            height: 1px;
        }
    }
`;

const BigLinkStyles = css`
    font-size: 44px;
    margin: 10px 0;
    display: block;
    line-height: 1.3;
    ${hoverStyles};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 34px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 26px;
        margin: 12px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 20px;
        margin: 6px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 18px;
        margin: 3px 0;
    }
`;

export const BigLink = styled(Link)`
    ${BigLinkStyles}
`;

export const SmallLink = styled(Link)`
    font-size: 20px;
    margin: 6px 0;
    display: block;
    ${hoverStyles};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin: 3px 0;
        font-size: 16px;
        &::before {
            display: none;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin: 2px 0;
        font-size: 14px;
    }
`;

export const ListItem = styled.li`
    position: relative;
    color: ${({theme}) => theme.colors.white};
    font-weight: ${({theme}) => theme.weights.semibold};
    display: flex;

    :nth-last-child(1),
    :nth-last-child(2),
    :nth-last-child(3) {
        ${BigLink} {
            color: ${({theme}) => theme.colors.gray_text};
        }
    }

    :nth-last-child(3) {
        ${BigLink} {
            margin-top: 32px;
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        :nth-last-child(3) {
            ${BigLink} {
                margin-top: 24px;
            }
        }
    }
`;

export const SubMenuSwitcher = styled.li``;

export const SubMenuHeader = styled.span`
    color: ${({theme}) => theme.colors.white};
    font-weight: ${({theme}) => theme.weights.semibold};

    cursor: pointer;
    ${BigLinkStyles}
`;

export const SubList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all 0.3s;
    padding-right: 40px;
    overflow: hidden;

    &.hide {
        height: 0px;
    }

    &.active {
        height: ${({subListHeight}) => `${subListHeight}px`};
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-right: 0px;
    }
`;

export const Copy = styled.div`
    display: flex;
    padding-bottom: 24px;
    margin-top: 32px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 24px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-top: auto;
    }
`;

export const CopyItem = styled.div`
    display: flex;

    &:first-child {
        margin-right: 50px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:first-child {
            margin-right: 25px;
        }
    }
`;

export const CopyLink = styled.a`
    font-size: 14px;
    color: ${({theme}) => theme.colors.gray_text};
    font-weight: ${({theme}) => theme.weights.semibold};

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 12px;
    }
`;

export const IvLogo = styled.img`
    width: 62px;
    margin-left: 8px;
`;
