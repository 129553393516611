import React, {useEffect, useRef, useState} from "react";
import {Address, CompanyLogo, ContactBox, Container, Fb, FbLink, FooterTitleWrapper, Icon, List, ListItem,} from "./styles";
import MapContainer from "./MapContainer/mapContainer";
import data from "data/footerData";
import WumaxLogo from "assets/image/wumax-logo_gray.png";
import uuid from "react-uuid";
import FbIcon from "assets/svg/fb.svg";
import {Paragraph, TitleXL} from "components/Typography/Typography";
import {footerToggle} from "../../store/menuColor";
import {useDispatch, useSelector} from "react-redux";

const Footer = () => {
  const {locale} = useSelector(state => state.modals);
  const footerRef = useRef(null);
  const [showMap, setShowMap] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {

    const changeMenuColor = () => {
      if (footerRef) {
        if (footerRef.current.getBoundingClientRect().top < 50) {
          dispatch(footerToggle(true))
        } else {
          dispatch(footerToggle(false))
        }
      }
    }

    if (window && window.innerWidth > 1100) {
      window.addEventListener("scroll", changeMenuColor)
    }

    return () => {
      if (window.innerWidth > 1100) {
        window.removeEventListener("scroll", changeMenuColor)
      }
    }
  }, [dispatch]);

  useEffect(() => {
    const mapTimeout = setTimeout(() => {
      setShowMap(true);
    }, 100);

    return () => {
      clearTimeout(mapTimeout);
    };
  }, []);

  return (
    <Container className="footer" ref={footerRef}>
      {showMap && (
        <MapContainer
          locale={locale}
          lat={50.57013621394964}
          lng={22.128386196047906}
          name="WUMAX"
        />
      )}
      <FooterTitleWrapper>
        <TitleXL>{locale === "en" ? data.titleEN : data.title}</TitleXL>
      </FooterTitleWrapper>
      <ContactBox>
        <CompanyLogo src={WumaxLogo} alt="WUMAX logo"/>
        <Address>
          <Icon src={data.address.icon} alt="Icon"/>
          <List>
            {locale === "en" ?
             data.addressEN.list.map((listItem) => (
               <ListItem key={uuid()}>
                 <Paragraph>{listItem}</Paragraph>
               </ListItem>
             ))
                             :
             data.address.list.map((listItem) => (
               <ListItem key={uuid()}>
                 <Paragraph>{listItem}</Paragraph>
               </ListItem>
             ))
            }
          </List>
        </Address>
        <List>
          {data.contact.list.map((listItem) => (
            <ListItem key={uuid()}>
              <Icon src={listItem.icon} alt="Icon"/>
              <Paragraph
                as="a"
                href={
                  listItem.text.includes("@")
                  ? `mailto:${listItem.text}`
                  : `tel:${listItem.text}`
                }
              >
                {listItem.text}
              </Paragraph>
            </ListItem>
          ))}
        </List>
        <FbLink
          href="https://www.facebook.com/WUMAX-216487208889478/"
          target="_blank"
          rel="noopener"
          title="Wumax Facebook"
        >
          <Fb src={FbIcon} alt="Facebook"/>
          <Paragraph as="span">Facebook</Paragraph>
        </FbLink>
      </ContactBox>
    </Container>
  );
};

export default Footer;
