import {createAction} from "store/actions";
import {COLOR} from "./consts";

export const heroToggle = (switcher) =>
  createAction(
    COLOR.hero, switcher
  );
export const footerToggle = (switcher) =>
  createAction(
    COLOR.footer, switcher
  );
