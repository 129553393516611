const pages = {
  pl: {
    homepage: "/en",
    kennametal: "/en/kennametal",
    haimer: "/en/haimer",
    widia: "/en/widia",
    walter: "/en/walter",
    "pozostali-producenci": "/en/others",
    "pozostali-producenci#maford": "/en/others#maford",
    "pozostali-producenci#yg1": "/en/others#yg1",
    "pozostali-producenci#vargus": "/en/others#vargus",
    "pozostali-producenci#zcc": "/en/others#zcc",
    toolboss: "/en/toolboss",
    "regeneracja-narzedzi": "/en/regeneration",
    "o-firmie": "/en/about",
    kontakt: "/en/contact",
    kalkulator: "/en/calculator",
    '404':'/en/404',
  },
  en: {
    homepage: "/",
    kennametal: "/kennametal",
    haimer: "/haimer",
    widia: "/widia",
    walter: "/walter",
    others: "/pozostali-producenci",
    "others#maford": "/pozostali-producenci#maford",
    "others#yg1": "/pozostali-producenci#yg1",
    "others#vargus": "/pozostali-producenci#vargus",
    "others#zcc": "/pozostali-producenci#zcc",
    toolboss: "/toolboss",
    regeneration: "/regeneracja-narzedzi",
    about: "/o-firmie",
    contact: "/kontakt",
    calculator: "/kalkulator",
    '404': '/404',
  },
};

export default pages;
