import {createAction} from "store/actions";
import {FORM_MODAL, LOCALE, PDF_MODAL} from "./consts";

export const pdfModalToggle = (id, sectionId) =>
  createAction(PDF_MODAL.toggle, {id, sectionId});

export const formModalToggle = () =>
  createAction(FORM_MODAL.toggle);

export const setLocale = (locale) => createAction(LOCALE.set, locale)
