// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-calculator-js": () => import("./../../../src/pages/en/calculator.js" /* webpackChunkName: "component---src-pages-en-calculator-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-haimer-js": () => import("./../../../src/pages/en/haimer.js" /* webpackChunkName: "component---src-pages-en-haimer-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-kennametal-js": () => import("./../../../src/pages/en/kennametal.js" /* webpackChunkName: "component---src-pages-en-kennametal-js" */),
  "component---src-pages-en-others-js": () => import("./../../../src/pages/en/others.js" /* webpackChunkName: "component---src-pages-en-others-js" */),
  "component---src-pages-en-regeneration-js": () => import("./../../../src/pages/en/regeneration.js" /* webpackChunkName: "component---src-pages-en-regeneration-js" */),
  "component---src-pages-en-toolboss-js": () => import("./../../../src/pages/en/toolboss.js" /* webpackChunkName: "component---src-pages-en-toolboss-js" */),
  "component---src-pages-en-walter-js": () => import("./../../../src/pages/en/walter.js" /* webpackChunkName: "component---src-pages-en-walter-js" */),
  "component---src-pages-en-widia-js": () => import("./../../../src/pages/en/widia.js" /* webpackChunkName: "component---src-pages-en-widia-js" */),
  "component---src-pages-haimer-js": () => import("./../../../src/pages/haimer.js" /* webpackChunkName: "component---src-pages-haimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-kennametal-js": () => import("./../../../src/pages/kennametal.js" /* webpackChunkName: "component---src-pages-kennametal-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-pozostali-producenci-js": () => import("./../../../src/pages/pozostali-producenci.js" /* webpackChunkName: "component---src-pages-pozostali-producenci-js" */),
  "component---src-pages-regeneracja-narzedzi-js": () => import("./../../../src/pages/regeneracja-narzedzi.js" /* webpackChunkName: "component---src-pages-regeneracja-narzedzi-js" */),
  "component---src-pages-toolboss-js": () => import("./../../../src/pages/toolboss.js" /* webpackChunkName: "component---src-pages-toolboss-js" */),
  "component---src-pages-walter-js": () => import("./../../../src/pages/walter.js" /* webpackChunkName: "component---src-pages-walter-js" */),
  "component---src-pages-widia-js": () => import("./../../../src/pages/widia.js" /* webpackChunkName: "component---src-pages-widia-js" */),
  "component---src-pages-work-2-js": () => import("./../../../src/pages/work2.js" /* webpackChunkName: "component---src-pages-work-2-js" */)
}

