import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {ThemeProvider} from "styled-components";
import theme from "shared/Themes/theme";
import {ParallaxProvider} from "react-scroll-parallax";
import {Transition} from "components/Transition";
import Navbar from "components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Cookies from "components/Cookies/Cookies";
import AOS from "aos";
import "aos/dist/aos.css";
import {useDispatch} from "react-redux";
import {setLocale} from "../store/modals"; // You can also use <link> for styles

const MainLayout = ({children, location}) => {
  const locale = location.pathname.split("/").find((item) => item === "en");
  const dispatch = useDispatch();

  useEffect(() => {
    if (locale) {
      dispatch(setLocale(locale))
    } else {
      dispatch(setLocale('pl'))
    }
  }, [dispatch, locale]);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: () => window.innerWidth < 1100,
      useClassNames: true,
      initClassName: false,
      animatedClassName: 'animate',
    });
  }, []);

  return (
    <ParallaxProvider>
      <ThemeProvider theme={theme}>
        <Navbar/>
        <Transition location={location}>
          {children}
        </Transition>
        <Footer/>
        <Cookies/>
      </ThemeProvider>
    </ParallaxProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
