import IvLogo from "assets/svg/logo-interactive.svg";
import Kennametal from "assets/image/navbar/menu-foto-kennametal.png";
import Other from "assets/image/navbar/menu-foto-other.png";
import Haimer from "assets/image/navbar/menu-foto-haimer.png";
import Walter from "assets/image/navbar/menu-foto-walter.png";
import Maford from "assets/image/navbar/menu-foto-maford.png";
import Yg from "assets/image/navbar/menu-foto-yg1.png";
import Vargus from "assets/image/navbar/menu-foto-vargus.png";
import Zcc from "assets/image/navbar/menu-foto-zcc.png";
import Toolboss from "assets/image/navbar/menu-foto-toolboss.png";
import Regen from "assets/image/navbar/menu-foto-regeneration.png";
import About from "assets/image/navbar/menu-foto-about.png";
import Contact from "assets/image/navbar/menu-foto-contact.png";
import Calc from "assets/image/navbar/menu-foto-calc.png";
import Widia from "assets/image/navbar/menu-foto-widia.png";

const useGetData = () => {
  return {
    pl: {
      links: [
        {
          text: "Kennametal",
          slug: "/kennametal",
          logo: Kennametal,
        },
        {
          text: "Pozostali producenci",
          logo: Other,
          links: [
            {
              text: "Haimer",
              slug: "/haimer",
              logo: Haimer,
            },
            {
              text: "Widia",
              slug: "/widia",
              logo: Widia,
            },
            {
              text: "Walter",
              slug: "/walter",
              logo: Walter,
            },
            {
              text: "M.A Ford",
              slug: "/pozostali-producenci#maford",
              logo: Maford,
            },
            {
              text: "YG1",
              slug: "/pozostali-producenci#yg1",
              logo: Yg,
            },
            {
              text: "VARGUS",
              slug: "/pozostali-producenci#vargus",
              logo: Vargus,
            },
            {
              text: "ZCC",
              slug: "/pozostali-producenci#zcc",
              logo: Zcc,
            },
          ],
        },

        {
          text: "ToolBoss",
          slug: "/toolboss",
          logo: Toolboss,
        },
        {
          text: "Regeneracja Narzędzi",
          slug: "/regeneracja-narzedzi",
          logo: Regen,
        },
        {
          text: "O firmie",
          slug: "/o-firmie",
          logo: About,
        },
        {
          text: "Kontakt",
          slug: "/kontakt",
          logo: Contact,
        },
        {
          text: "Kalkulator Prędkości Skrawania",
          slug: "/kalkulator",
          logo: Calc,
        },
      ],
      copy: {
        privacy: {
          text: "Polityka prywatności",
          slug: "/polityka-prywatnosci.pdf",
        },
        creator: {
          text: "Realizacja:",
          slug: "https://interactivevision.pl",
          logo: IvLogo,
        },
      },
    },
    en: {
      links: [
        {
          text: "Kennametal",
          slug: "/en/kennametal",
          logo: Kennametal,
        },
        {
          text: "Other Manufacturers",
          logo: Other,
          links: [
            {
              text: "Haimer",
              slug: "/en/haimer",
              logo: Haimer,
            },
            {
              text: "Widia",
              slug: "/en/widia",
              logo: Widia,
            },
            {
              text: "Walter",
              slug: "/en/walter",
              logo: Walter,
            },
            {
              text: "M.A Ford",
              slug: "/en/others#maford",
              logo: Maford,
            },
            {
              text: "YG1",
              slug: "/en/others#yg1",
              logo: Yg,
            },
            {
              text: "VARGUS",
              slug: "/en/others#vargus",
              logo: Vargus,
            },
            {
              text: "ZCC",
              slug: "/en/others#zcc",
              logo: Zcc,
            },
          ],
        },

        {
          text: "ToolBoss",
          slug: "/en/toolboss",
          logo: Toolboss,
        },
        {
          text: "Tool reconditioning",
          slug: "/en/regeneration",
          logo: Regen,
        },
        {
          text: "About Us",
          slug: "/en/about",
          logo: About,
        },
        {
          text: "Contact",
          slug: "/en/contact",
          logo: Contact,
        },
        {
          text: "Cuting Speed Calculator",
          slug: "/en/calculator",
          logo: Calc,
        },
      ],
      copy: {
        privacy: {
          text: "Privacy policy",
          slug: "/polityka-prywatnosci.pdf",
        },
        creator: {
          text: "Realisation:",
          slug: "https://interactivevision.pl",
          logo: IvLogo,
        },
      },
    },
  };
};

export default useGetData;
