import {useEffect, useState} from "react";
const isBrowser = typeof window !== `undefined`;
const func = () => {
  if (isBrowser) {

    if (window.matchMedia("(orientation: portrait)").matches) {
      return ('portrait');
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      return ('landscape');
    }
  }
}

export const useOrientationChange = () => {

  const [orientation, setOrientation] = useState(func());

  const setter = () => {
    setOrientation(func());

  }

  useEffect(() => {
    window.addEventListener("resize", setter);

    return () => {window.removeEventListener('resize', setter)}
  }, [])

  return orientation
}

