import styled, { css } from "styled-components";

const defaultStyles = css`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.weights.semibold};
  background-color: ${({ theme }) => theme.colors.yellow};
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  outline: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding: 12px;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow_dark};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 24px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 10px;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  padding: 0 60px;
  background: ${({ theme }) => theme.colors.black};
  box-shadow: 0px -2px 8px -3px ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0;
  }

  .cookies-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 24px 0;

    & > div:first-of-type {
      width: 100%;
    }

    #rcc-confirm-button {
      ${defaultStyles}
      margin-left: 24px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tablet_big}) {
      padding: 24px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      padding: 0px;
      padding-bottom: 24px;
      flex-direction: column;
      text-align: center;

      #rcc-confirm-button {
        margin-left: 0;
      }
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 30px 0 0;
    flex-direction: column;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  max-width: 800px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    max-width: 600px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding: 0 48px 0 24px;
    max-width: 500px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
  }
`;

export const Title = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: ${({ theme }) => theme.weights.normal};
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 16px;
  font-size: 20px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 16px;
  }
`;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.weights.light};
  font-size: 10px;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.white};
`;

export const PolicyButton = styled.a`
  ${defaultStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;
