import CookieConsent from "react-cookie-consent";
import React from "react";
import {
  Container,
  FlexContainer,
  TextBox,
  Text,
  PolicyButton,
} from "./styles.js";
import { useEngCookiesData } from "lang/en/cookies.js";
import { useCookiesData } from "lang/cookies.js";
import { SectionWrapper } from "shared/Wrappers/wrappers";
import {useSelector} from "react-redux";

const Cookies = () => {
 const {locale} = useSelector(state => state.modals);
  const engData = useEngCookiesData();
  const data = useCookiesData();
  const cookies = locale === "en" ? engData : data;

  return (
    <Container>
      <SectionWrapper>
        <CookieConsent
          disableStyles={true}
          buttonText={cookies.btn1}
          location="bottom"
          cookieName="Website cookies"
          buttonClasses="btn"
          expires={150}
          style={{ alignItems: "center", width: "100%" }}
          containerClasses="cookies-wrapper"
        >
          <FlexContainer>
            <TextBox>
              <Text>{cookies.text}</Text>
            </TextBox>
            <PolicyButton
              href="https://www.wumax.pl/polityka-prywatnosci.pdf"
              target="_blank"
              rel="noopener"
            >
              {cookies.btn2}
            </PolicyButton>
          </FlexContainer>
        </CookieConsent>
      </SectionWrapper>
    </Container>
  );
};

export default Cookies;
