import {COLOR} from "./consts"

export const initialState = {
  footer: false,
  hero: false,
}

export const menuColor = (state = initialState, {type, payload}) => {
  switch (type) {
    case COLOR.hero:
      return {
        ...state,
        hero: payload
      }
      case COLOR.footer:
      return {
        ...state,
        footer: payload
      }

    default:
      return state
  }
}
