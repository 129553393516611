import {FORM_MODAL, LOCALE, PDF_MODAL} from "./consts"

export const initialState = {
  pdfs: {},
  openForm: false,
  locale: 'pl',
}

export const modals = (state = initialState, {type, payload}) => {
  switch (type) {
    case PDF_MODAL.toggle:
      return {
        ...state,
        pdfs: {
          ...state.pdfs,
          [payload.sectionId]: state.pdfs[payload.sectionId] === `${payload.id}` ? null : `${payload.id}`
        }
      }
    case FORM_MODAL.toggle:
      return {
        ...state,
        openForm: !state.openForm
      }
    case LOCALE.set :
      return {
        ...state,
        locale: payload
      }

    default:
      return state
  }
}
